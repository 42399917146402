
.countdown-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.countdown-container > div {
    display: inline-flex;
    margin-right: 2px;
}

.countdown-container.countdown-green {
    color: #00b90f;
}

.countdown-container.countdown-orange {
    color: #f7ac43;
}

.countdown-container.countdown-red {
    color: #ed5e5e;
}
