/* Provide sufficient contrast against white background */
body {
    font-family: 'Niramit';
    font-size: 16px;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons, .material-icons-outlined {
    vertical-align: middle;
}

    /* Rules for sizing the icon. */
    .material-icons.md-18, .material-icons-outlined.md-18 {
        font-size: 18px; 
    }

    .material-icons.md-24, .material-icons-outlined.md-24 {
        font-size: 24px;
    }

    .material-icons.md-36, .material-icons-outlined.md-36 {
        font-size: 36px;
    }

    .material-icons.md-48, .material-icons-outlined.md-48 {
        font-size: 48px;
    }

    /* Rules for using icons as black on a light background. */
    .material-icons.md-dark {
        color: rgba(0, 0, 0, 0.54);
    }

        .material-icons.md-dark.md-inactive {
            color: rgba(0, 0, 0, 0.26);
        }

    /* Rules for using icons as white on a dark background. */
    .material-icons.md-light {
        color: rgba(255, 255, 255, 1);
    }

        .material-icons.md-light.md-inactive {
            color: rgba(255, 255, 255, 0.3);
        }

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container-header {
    font-size: 20px;
}

#refreshButton{
    display:none;
}

.qc-green {
    color: #00b90f;
}

.qc-orange {
    color: #f7ac43;
}

.qc-red {
    color: #ed5e5e;
}
