.nextup {
    padding: 0px;
    background-color: #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    margin-bottom: 20px;
}

    .nextup .nextup-header {
        padding: 10px 20px 10px 20px;
        text-align: left;       
    }

    .nextup .nextup-content {
        border-top: 2px solid #fff;
        padding: 10px 20px;
    }

    .nextup table {
        width: 100%;
        table-layout: fixed;
    }

        .nextup table tr td:nth-child(2) {
            width: 30%;
        }

        .nextup table tr td:last-child {
            width: 20%;
        }

    .nextup table {
        width: 100%;
    }

    .nextup .npc-spawn-instance {
        text-transform: uppercase;
    }


    .nextup .npc-spawn .npc-spawn-name a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }
