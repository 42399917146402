.npcs-container {
    width: 100%;
}

.npcs {
    margin-left: 0px;
    margin-right: 0px;
}

    .npcs > div {
        margin-bottom: 20px;
    }

        .npcs > div > div {
            height: 100%;
        }

.npc {
    background-color: #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%;
}

    .npc .npc-name {
        padding: 10px 20px 0px 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

        .npc .npc-name .material-icons {
            font-size: 18px;
        }

    .npc .npc-note {
        height: 20px;
        text-align: center;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 10px;
        margin-bottom: 5px;
    }

    .npc .npc-shortname {
        text-align: center;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 10px;
    }

    .npc .npc-spawns {
        border-top: 2px solid #fff;
        padding: 5px 10px;

    }

        .npc .npc-spawns .npc-spawn .npc-spawn-time {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .npc .npc-spawns .npc-spawn .npc-spawn-instance {
            text-transform: uppercase;
        }
